body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.waiting_presc {
    height: 100vh;
    background-color: #fff;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999999999  ;
    text-align: center;

    .wpinner {
        padding: 30px;
    }
    .carousel-item {
        p {
            font-size: 22px;
            line-height: 30px;
            color: #333;
            background: #fff;
        }
    }
    .carousel-indicators {
        display: none;
    }
    .carousel-control-prev, .carousel-control-next {
        display: none;
    }
}